import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import AppBackground from "../../../components/src/AppBackground.web";
import GenericCard from "../../../components/src/GenericCard.web";
import OrganizationSignUpController from "./OrganizationSignUpController.web";
import { styled } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import { Box, InputLabel, Tooltip } from "@material-ui/core";

export default class OrganizationSignUp extends OrganizationSignUpController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  renderBlock = (step: number, formik: any) => {
    const Block1 = (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="title">Organization Details</div>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>
                ORGANISATION'S FULL NAME
              </CustmizedInputLabel>
              <Tooltip
                title="Full legal name of your organisation as registered with the local authority."
                placement="right"
                arrow
                className="tooltip"
              >
                <InfoOutlinedIcon className="infoIcon" />
              </Tooltip>
            </div>
            <div className="Custmizedinputs">
              <input
                className="textbox"
                name="OrganizationName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.OrganizationName}
              />
            </div>
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>ORGANISATION'S UEN</CustmizedInputLabel>
              <Tooltip
                title="The unique registration number assigned to your organization by the local authority."
                placement="right"
                arrow
                className="tooltip"
              >
                <InfoOutlinedIcon className="infoIcon" />
              </Tooltip>
            </div>
            <div className="Custmizedinputs">
              <input
                className="textbox"
                name="OrganizationUEN"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.OrganizationUEN}
              />
            </div>
          </InputContainer>
        </Grid>
      </Grid>
    );
    const Block2 = <div>2</div>;
    const Block3 = (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="title">Admin Details</div>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>FULL NAME</CustmizedInputLabel>
              <InfoOutlinedIcon className="infoIcon" />
            </div>
            <div className="Custmizedinputs">
              <input
                className="textbox"
                name="AdminName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.AdminName}
              />
            </div>
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>Contact Number</CustmizedInputLabel>
              <InfoOutlinedIcon className="infoIcon" />
            </div>
            <div className="Custmizedinputs">
              <PhoneInput
                specialLabel={""}
                country={"us"}
                onChange={formik.handleChange}
                value={formik.values.AdminNo}
                containerStyle={styles.container}
                buttonStyle={styles.button}
                inputStyle={styles.input}
              />
            </div>
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>Designation</CustmizedInputLabel>
              <InfoOutlinedIcon className="infoIcon" />
            </div>
            <div className="Custmizedinputs">
              <input
                className="textbox"
                name="AdminDesignation"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.AdminDesignation}
              />
            </div>
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>Admin Department</CustmizedInputLabel>
              <InfoOutlinedIcon className="infoIcon" />
            </div>
            <div className="Custmizedinputs">
              <input
                className="textbox"
                name="AdminDepartment"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.AdminDepartment}
              />
            </div>
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>Email Address</CustmizedInputLabel>
              <InfoOutlinedIcon className="infoIcon" />
            </div>
            <div className="Custmizedinputs">
              <input
                className="textbox"
                name="AdminEmail"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.AdminEmail}
              />
            </div>
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>PASSWORD</CustmizedInputLabel>
              <InfoOutlinedIcon className="infoIcon" />
            </div>
            <div className="Custmizedinputs">
              <CustmizedPassword
                disableUnderline
                id="standard-adornment-password"
                type={this.state.showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onChange={(e) => console.log(e)}
                      value={formik.values.phone}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          </InputContainer>
        </Grid>
      </Grid>
    );
    const Block4 = (
      <Grid container>
        <Grid item xs={12}>
          <div className="title">Review & Submit</div>
        </Grid>
        <Grid item xs={12}>
          <ReviewBox>
            <ButtonContainer>
              <h4>Organisation Details</h4>
              <h4>icon</h4>
            </ButtonContainer>
          </ReviewBox>
        </Grid>
      </Grid>
    );

    if (step === 0) {
      return Block1;
    }
    if (step === 1) {
      return Block2;
    }
    if (step === 2) {
      return Block3;
    }
    if (step === 3) {
      return Block4;
    }
  };
  render() {
    return (
      // Required for all blocks
      <OrganizationPageStyle data-test-id="OrganizationSignUp">
        <AppBackground>
          <GenericCard>
            <div >
              <Stepper activeStep={this.state.activeStep} alternativeLabel>
                {this.state.stepLabels.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>
                <div>
                  <Formik
                    initialValues={{
                      OrganizationName: "",
                      OrganizationUEN: "",
                      BusinessRegistrationDoc: "",
                      PeincipalOrganization: "",
                      PrincipalRegistrationDoc: "",
                      AuthLetter: "",
                      AdminName: "",
                      AdminNo: "",
                      AdminDepartment: "",
                      AdminDesignation: "",
                      AdminEmail: "",
                      AdminPassword: "",
                    }}
                    onSubmit={(values) => {
                      console.log(values);
                      alert(JSON.stringify(values, null, 2));
                    }}
                  >
                    {(formik) => (
                      <form onSubmit={formik.handleSubmit}>
                        {this.renderBlock(this.state.activeStep, formik)}
                        <ButtonContainer>
                          <Button
                            disabled={this.state.activeStep === 0}
                            onClick={this.handleBack}
                            data-test-id="back"
                          >
                            Back
                          </Button>
                          {this.state.activeStep ===
                          this.state.stepLabels.length - 1 ? (
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                            >
                              Finish
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handleNext}
                              data-test-id="next"
                            >
                              Next
                            </Button>
                          )}
                        </ButtonContainer>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </GenericCard>
        </AppBackground>
      </OrganizationPageStyle>
    );
  }
}

const styles = {
  container: {
    width: "100%",
    height: "56px",
    borderRadius: "8px",
    border: "1px solid gray",
  },
  button: {
    border: "none",
    borderRadius: "9px",
  },
  input: {
    height: "56px",
    width: "100%",
    border: "none",
    borderRadius: "9px",
  },
};

const ButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "1.5rem",
});
const InputContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
});
const CustmizedInputLabel = styled(InputLabel)({
  fontSize: "14px",
  lineHeight: "16px",
  letterSpacing: "0em",
  textAlign: "left",
  fontFamily: "Asap",
  display: "contents",
  color: "black !important",
  textTransform: "uppercase",
});
const CustmizedPassword = styled(Input)({
  width: "100%",
  height: "56px",
  padding: "10px 22px 10px 12px",
  borderRadius: "8px",
  border: "1px solid gray",
});
const ReviewBox = styled(Box)({
  height: "auto",
  borderRadius: "16px",
  border: "1px",
  backgroundColor: "#CDCDCD",
  padding: "1rem",
});
const OrganizationPageStyle = styled("div")({
  "& .title": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "1.3rem",
    margin: "1rem 0 2rem 0",
    fontFamily: "Asap",
  },
  "& .tooltip": {
    backgroundColor: "white !important",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 11,
  },
  "& .setDisplay": {
    display: "flex",
  },
  "& .Custmizedinputs": {
    width: "100%",
    height: "56px",
    padding: "10px 22px 10px 0px",
    borderRadius: "8px",
    backgroundcolor: "red",
    borderRadious: "8px",
  },
  "& .textbox": {
    width: "100%",
    height: "56px",
    padding: "10px 22px 10px 12px",
    borderRadius: "8px",
    border: "1px solid gray",
  },
  "& .infoIcon": {
    fontSize: "1.2rem",
    marginLeft: "0.6rem",
  },
});
// Customizable Area End